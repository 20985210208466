import "../core/src/modules/explorer/views/tree/node.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/modules/explorer/views/tree/node.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61W3U7jOhC+71NYlY4EF0ZpYQsYIZ33OEKrqT1JZuvYke2U7R7x7is7SRuXtrBir9pk/r/5Zib/bnBXOmjQs++LH7t7V5fA/p8xVvyTfhizLUgKO8EWTzPG3maMrS7I7t/LilH2cMHu8YLdojhr+Da7GdNeJBVptXWCkanRUXiaKiyTgiLfathFFU0GeanxZ/QFmirDKWDjBZNoArr4em2dQscdKOq8YHdtUg74M/BkIZjGMjydiMzYK6lQi5R+fGzI8BqpqoNgt0XvqPPouEeNMghmrMHkqXM+umotjWm0oBSZSrBiTKG0JnBPv1CwLbgrzqEsUz3pfXOdrKynQNYI5lBDoG1y34CryPBgW8FSGlOMRG236BJSa5CbytnOqKMQSYWneq9z6/8UBOAgY6jneXAdzl++5kuRh7VGlXkbAVJYQqen6Ge+U5c8SmsUuN0+CBuB5bhFE/wI/IngDqqKTJUFP/Dz5ltmdNuLt+hKbV8Fq0kpNHu6HASoNbWefOJITQG5b0FizOPVQXuBjZGsA/fPFtw6asDtcjTvcu5/QHrXk3Rg2oFFsPZWdyGxKB/TjxEdiHWU0Y/OByp3cQTKPi2ORuVRyVAg0FnQxamgoHUW89t0I2RAkbRmAlQ2TcvjmVglN+Mwr3pQxkEenz8L7ViwtCagCRdWzXJYNQ7MiET6X1rXsOJm6c+x4Ki4aSU9raXVGlp/NFR754I5GyDgFX8sFFYnXZwcy8lk3H6KFKsvbZv7P+L0O+AT2XwAN93Vd+N2TcLakdkkhk/CPhzQOtkWhuCRk+G2y+/Pw9fRf8xLfgfoo7BG77isSavjazcM0UR7nVSm52Dg3HBteDxtYzdGo+LsZZl4lpdbehCMPJ0s57WNyIOKmzLfbtbqNbhBeD3p2rIYzunRCK2OJrnH5IPcVd+m4DB20QfXyaj9PG9ggz2085deJ26t/kigSvv9eV6C9jh/EVCGgdh7ws3n2aJYays35/frL05G7bf9J6eDsfMt6+/9BDUJWl6lbyv+zuA623HpA+ZvQ9TLbYvmFGpgqIEelcN36cIzMmWkMrL48Qbuafb2GwNsFJrCCgAA\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var collapseContentPlaceholder = '_1jy7rhf9';
export var collapsedIcon = '_1jy7rhf8';
export var collapsedIconContainer = '_1jy7rhf6';
export var contentContainer = '_1jy7rhfb';
export var draggedOverEffect = '_1jy7rhfd';
export var draggingContainer = '_1jy7rhfc';
export var icon = '_1jy7rhf5';
export var iconsContainer = '_1jy7rhf7';
export var itemContent = '_1jy7rhf3';
export var itemRoot = '_1jy7rhf2';
export var levelIndent = 'var(--_1jy7rhf0)';
export var linkItemRoot = '_1jy7rhf1';
export var postfix = '_1jy7rhf4';